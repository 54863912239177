:root {
    --leftSideWrapperWidth: 240px;
}

.LeftSideDrawerList {
    padding: 0 5px 0 15px !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
}

.LeftSideDrawerList>div {
    border-radius: 100px;
    width: 100%;

}



.leftDrawerHeader {
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    align-items: center;
    width: 100%;
    margin-top: 50px !important;
    margin-bottom: 60px !important;

}

.leftDrawerHeader h2 {
    font-size: 26px;
    font-weight: bolder;
}