.drawer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.drawerBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 30px;
    padding: 20px;
    max-width: 600px;
    min-width: 600px;

    @media screen and (max-width: 1000px) {
        max-width: 100%;
        width: 100vw;
        min-width: unset;
        padding-bottom: var(--mobileLeftDrawerBleed);
    }
}

.drawer .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.drawer .col {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}

.textArea {
    width: 100%;
    height: 100px;
}

.addOptionsWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.optionChipsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    width: 100%;
}

.addOptionsWrapper .inputsWarapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 15px;
}

.addOptionsWrapper .optionRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 15px;
}


.drawerButton {
    width: 200px;
    height: 54px;
    border: 2px solid #1b1b1b;
    background: #1b1b1b;
    border-radius: 100px;
    color: #fff;
    font-size: 15px;
    font-weight: normal;
    transition: .2s;
    position: relative;
    position: relative;
    -webkit-transition-duration: 0.4s;
    /* Safari */
    transition-duration: 0.4s;
    overflow: hidden;
    cursor: pointer;

    @media screen and (max-width: 1000px) {
        width: 100%;
    }
}


.drawerButton:hover {
    border: 2px solid #1b1b1b;
    background: #fff;
    border-radius: 100px;
    font-weight: bold;
    color: #1b1b1b;
    transition: .2s;
}


.drawerButton:after {
    content: "";
    background: #1b1b1b;
    display: block;
    position: absolute;
    width: 100%;
    height: 500%;
    border-radius: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.8s;
    pointer-events: none;
}

.drawerButton:active:after {
    width: 0;
    height: 0;
    opacity: 1;
    transition: 0s
}

.switchAndLabelWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: auto;
}

.productDrawerButton {
    position: absolute;
    right: 50px;
}