.dialogNoButton {
    width: auto;
    height: auto;
    background: #fff;
    color: #1d1d1d;
    border: 1.5px solid #1d1d1d;
    padding: 5px 15px;
    border-radius: 100px;
}

.dialogYesButton {
    width: auto;
    height: auto;
    background: #1d1d1d;
    color: #fff;
    border: 1px solid #1d1d1d;
    padding: 5px 15px;
    border-radius: 100px;
}