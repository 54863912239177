.MenuHomeWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgb(247, 247, 247);
    position: relative;
}


.MenuHomeWrapper #section-filter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    background: #fff;
}

.MenuHomeWrapper #section-filter .titleWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 32px 24px
}


.filterAndSvgWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 16px 16px 0;
}


.filterAndSvgWrapper>button {
    border: none;
    background: transparent;
    width: 20px;
    height: 20px;
}

.filterAndSvgWrapper>button svg {
    width: 100%;
    height: 100%;
}

.fakeSearchInput {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 40px;
    cursor: text;
}


.fakeSearchInput svg {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    fill: rgba(0, 0, 0, 0.4);
}


.fakeSearchInput span {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.4);
}




.MenuHomeWrapper .categoriesWrapper {
    width: 100%;
    height: auto;
    min-height: 150px;
    position: relative;

}

.MenuHomeWrapper .categoriesScrollWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
    gap: 0.5rem;
    overflow-y: hidden;
    overflow-x: auto;
    scroll-behavior: smooth;
}

.MenuHomeWrapper .categoriesScrollWrapper::-webkit-scrollbar {
    display: none;
}

.MenuHomeWrapper .categoriesWrapper::before {
    content: "";
    width: 150px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

.MenuHomeWrapper .categoriesWrapper:has(button.right[disable="true"])::before {
    display: none;
}

button.right[disable="true"] {
    display: none !important;
}


button.left[disable="true"] {
    display: none !important;
}

button.left[disable="false"] {
    display: flex !important;
}

.MenuHomeWrapper .categoriesWrapper::after {
    content: "";
    width: 150px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    display: none;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

.MenuHomeWrapper .categoriesWrapper:has(button.left[disable="true"])::after {
    display: none;
}

.MenuHomeWrapper .categoriesWrapper:has(button.left[disable="false"])::after {
    display: flex;
}
.MenuHomeWrapper .categoriesWrapper>button.right {
    position: absolute;
    top: 50%;
    right: 0;
    width: 40px;
    height: 100%;
    stroke: rgb(var(--primary));
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    pointer-events: all;
}

.MenuHomeWrapper .categoriesWrapper>button.right svg {
    width: 24px;
    height: 24px;
    stroke: rgb(var(--primary));
    transform: translateY(-50%);
    transition: .2s;
}

.MenuHomeWrapper .categoriesWrapper>button.right:hover svg {
    transform: translateX(8%) translateY(-50%);
    transition: .2s;
}


.MenuHomeWrapper .categoriesWrapper>button.left {
    position: absolute;
    top: 50%;
    left: 0;
    width: 40px;
    height: 100%;
    stroke: rgb(var(--primary));
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    pointer-events: all;
    z-index: 1;
    display: none;
}

.MenuHomeWrapper .categoriesWrapper>button.left svg {
    width: 24px;
    height: 24px;
    stroke: rgb(var(--primary));
    transform: translateY(-50%) rotate(180deg);
    transition: .2s;
}

.MenuHomeWrapper .categoriesWrapper>button.left:hover svg {
    transform: translateX(-8%) translateY(-50%) rotate(180deg);
    transition: .2s;
}

.mini-categoriesWrapper {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: calc(30%);
    height: 70px;
    background: #fff;
    animation: mini-categoriesWrapper-close 0s linear forwards;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    gap: .5rem;
    padding: 0 .5rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


@media screen and (max-width: 500px) {

    .mini-categoriesWrapper {
        max-width: 100%;
        width: 100%;
        min-width: 100%;
    }
}



.mini-categoriesWrapper::-webkit-scrollbar {
    display: none;
}

.mini-categoriesWrapper>a {
    width: auto;
    height: 38px;
    padding: 8px 16px;
    border: 1px solid rgb(var(--primary));
    white-space: nowrap;
    border-radius: 5px;
    background: #fff;
    color: var(--p-color);
    cursor: pointer;
}

.mini-categoriesWrapper>a.active {
    background: rgb(var(--primary));
    color: #fff;
}

.mini-categoriesWrapper>a>label {
    pointer-events: none;
}

@keyframes mini-categoriesWrapper {
    from {
        transform: translateY(-100%);
        display: none;

    }

    to {
        transform: translateY(0%);
        display: block !important;
    }
}

@keyframes mini-categoriesWrapper-close {
    from {
        transform: translateY(0%);
        display: block;
    }

    to {
        transform: translateY(-100%);
        display: none;
    }
}

.MenuHomeWrapper .categoriesWrapper::-webkit-scrollbar {
    display: none;
}

.MenuHomeWrapper .categoryBox {
    width: 104px;
    height: 102px;
    min-width: 104px;
    min-height: 102px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.MenuHomeWrapper .categoryBox .imgWrapper {
    max-width: 100%;
    max-height: 100%;
    width: 104px;
    height: 78px;
    border: 2px solid rgb(var(--primary));
    border-radius: 5px;
}

.MenuHomeWrapper .categoryBox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
}


.MenuHomeWrapper .categoryBox label {
   text-overflow: ellipsis;
   overflow: hidden;
   width: 100%;
   white-space: nowrap;
   text-align: center;
   color: #000;
}


.productsWrapper {
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
}

.categoryAndOwnProducts {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.categoryNameAndImageWrapper {
    width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: .25rem;


}

.categoryAndOwnProducts .imageWrapper {
    width: 100%;
    height: 181px;
    border-radius: 8px;
    background: #fff;
}


.categoryImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
}

.categoryTitle {
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: rgb(87, 89, 98);
    font-weight: 700;
}

.categoryDescp {
    font-size: 16px;
    color: rgb(87, 89, 98);
    text-align: center;
}


.menuWarningBox {
    width: calc(100% - 32px);
    height: auto;
    border: 1px solid rgba(var(--primary), .5);
    background: rgba(var(--primary), 0.05);
    border-radius: 5px;
    padding: 10px;
}


.menuWarningBox p {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: var(--p-color);
    filter: opacity(0.5);
}