body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --mobileLeftDrawerBleed: 32px;
}

.LeftSideDrawer {
  width: 250px !important;
  height: auto;
  position: fixed;
  background: #fff;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  transition: .3s;

  @media screen and (max-width: 1000px) {
    top: unset;
    bottom: 0;
    left: 0;
    transform: translateY(calc(100% - var(--mobileLeftDrawerBleed)));
    width: 100% !important;
    height: 50%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
}
.LeftSideDrawer .fade {
  height: 100vh;
  opacity: 0;
  pointer-events: none;
}
.LeftSideDrawer.open .fade {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 1;
  top:0;
  transform: translateY(-100%);
  transition: opacity .3s;
  opacity: 1;
  pointer-events: all;
}

@media screen and (max-width: 1000px) {


  .LeftSideDrawer::before {
    content: "";
    position: absolute;
    top: calc(var(--mobileLeftDrawerBleed) / 2);
    left: 50%;
    transform: translate(-50%, -50%);
    /* background: rgb(224, 224, 224); */
    background: #1b1b1b;
    width: 60px;
    border-radius: 100px;
    height: 5px;
    pointer-events: none;
  }
}

.LeftSideDrawer.open {
  box-shadow: rgba(50, 50, 93, 0.25) 0px -10px 12px 0px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
 

  @media screen and (max-width: 1000px) {
    transform: translateY(calc(0%));
  }
}

.LeftSideDrawer div[role="button"]:hover {
  background: #f3f3fb !important;
}