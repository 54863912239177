.ownedProdcutRow {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
    background: #fff;
    padding: 12px;
    width: 100%;
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
    transition: .2s;
    padding: 15px 12px 12px 12px;
}

.ownedProdcutRow .first {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.ownedProdcutRow .second {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}


.ownedProdcutRow:hover {
    transform: translateY(4px);
    transition: .2s;
}


.ownedProdcutRow .left {
    width: calc(100% - 100px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
}


.ownedProdcutRow .left .title {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
    color: rgb(87, 89, 98);
}

.ownedProdcutRow .left .description {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: rgb(87, 89, 98);
}



.ownedProdcutRow .left .price {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    color: rgb(216, 43, 43);

}

.ownedProdcutRow .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
}


.ownedProdcutRow .right img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}