@font-face {
    font-family: Nunito Sans;
    src: url(../../../public/assets/fonts/OpenSans_VariableFont_wdth_wght\ \(1\).ttf);
}


a {
    text-decoration: none;
    color: unset;
}

:root {
    --primary: 216, 43, 43
}

.HeaderWrapper {
    width: 30%;
    min-width: 500px;
    height: 48px;
    background: rgb(var(--primary));
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    position: fixed;

}

.HeaderWrapper>.goBackButton {
    background: transparent;
    border: none;
    width: 24px;
    height: 24px;
}

.HeaderWrapper>.goBackButton svg {
    stroke: #fff;
    width: 100%;
    height: 100%;
}


.HeaderWrapper>.menuButton {
    background: transparent;
    border: none;
    width: 24px;
    height: 24px;
}

.HeaderWrapper>.menuButton svg {
    stroke: #fff;
    width: 100%;
    height: 100%;
}

.HeaderWrapper>h1 {
    font-family: "Nunito Sans", sans-serif;
    color: #fff;
    font-size: 16px;
}