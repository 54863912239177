.productCardWrapper {
    width: 100%;
    height: auto;
}

.productCardWrapper img {
    width: 100px;
    height: 100%;
    object-fit: cover;

    @media screen and (max-width: 1000px) {
        width: 50px;
    }
}

.productCardWrapper .infoWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: auto;
    height: 100%;
    padding: 5px;
}

.productCardWrapper .infoWrapper h2 {
    font-family: roboto;
    font-weight: bold;
    color: black;
    font-size: 18px;

    @media screen and (max-width: 1000px) {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
        max-width: 250px;
    }
}

.productCardWrapper .infoWrapper h3 {
    font-family: roboto;
    font-weight: 100;
    font-size: 14px;
    color: rgb(90, 90, 90)
}

.productCardWrapper .buttonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

