.filterButtonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    background: #acacac;
    border-radius: 100px;
    padding: 4px;

    @media screen and (max-width: 1000px) {
        gap: 5px;

    }
}

.filterButton {
    width: 50%;
    border: 2px solid #fff;
    background: #fff;
    border-radius: inherit;
    transition: .2s;
    color: #1b1b1b;
    font-size: 18px;
    height: 100%;
    white-space: nowrap;


    @media screen and (max-width: 1000px) {
        font-size: 14px;
    }
}

.filterButton.active {
    border: 2px solid #1b1b1b;
    background: #1b1b1b;
    color: #fff;
}