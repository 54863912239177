* {
    font-family: roboto;
}

.MenuTemplateLeftWrapper:has(.productPageWrapper)>.HeaderWrapper {
    display: none;
}

.MenuTemplateLeftWrapper:has(.productPageWrapper)>div {
    margin-top: 0;
}

.productPageWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
}

.productImageWrapper {
    max-width: 30%;
    min-width: 500px;
    width: 100%;
    height: 60%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1
}


@media screen and (max-width: 500px) {

    .productImageWrapper {
        max-width: 100%;
        min-width: 100%;
    }
}

.productImageWrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.productPageWrapper .body {
    width: 100%;
    height: auto;
    min-height: calc(40% + 135px);
    margin-top: calc(95%);
    z-index: 3;
    background: #ffffff;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
}

.productPageWrapper .body .firstWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: auto;
    gap: 10px;
    padding: 24px
}

.productPageWrapper .body .name {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 700;
    color: rgb(87, 89, 98);
}


.productPageWrapper .body .description {
    color: rgb(87, 89, 98);

}


.productPageWrapper .body .price {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
    color: rgb(var(--primary))
}

.productPageWrapper .tagsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
}

.floatingBackButton {
    width: 40px;
    height: 40px;
    position: fixed;
    z-index: 2;
    top: 15px;
    left: 15px;
    border: none;
    border-radius: 100px;
    background: #fff;
    padding: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: .2s;

}

.floatingBackButton:hover {
    transform: translateY(4px);
    transition: .2s;
}

.floatingBackButton>svg {
    width: 100%;
    height: 100%;
}

.optionsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    width: 100%;
    margin-top: 2rem;
}


.optionsWrapper>div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    width: 100%;
}


.optionsWrapper>div span:first-child {
    font-size: 16px;
    color: var(--p-color);
}

.optionsWrapper>div span:last-child {
    font-size: 16px;
    color: rgb(var(--primary));
}

.goesWellWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    width: calc(100%);
    margin-top: 3rem;
    background: rgb(247, 247, 247);
    padding: 32px 16px 40px;
}


.goesWellWrapper h3 {
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: var(--p-color);
    width: 100%;
    text-align: start;
}

.goesWellRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    border: 2px solid rgba(0, 0, 0, .1);
}

.goesWellRow img {
    width: 86px;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.goesWellRow div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    padding: 5px;
}


.goesWellRow div>span:first-child {
    font-weight: 700;
    color: var(--p-color);
    font-size: 1rem;
    line-height: 1.5rem;
}


.goesWellRow div>span:last-child {
    font-weight: 600;
    color: rgb(var(--primary));
    font-size: 1rem;
    line-height: 1.5rem;
}