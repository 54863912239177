.CategoryCardWrapper {
    display: flex;
    width: 100%;
}

.CategoryCardWrapper img {
    width: 100px;
    height: 100%;
    object-fit: cover;
}

.CategoryCardWrapper .infoWrapper {
    height: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}


.CategoryCardWrapper .infoWrapper  h2 {
    font-family: roboto;
    font-weight: bold;
    color: black;
    text-overflow: ellipsis;
    font-size: 18px;
}

.CategoryCardWrapper .infoWrapper  h3 {
    font-family: roboto;
    font-weight: 100;
    font-size: 14px;
    color: rgb(90, 90, 90)
}

.CategoryCardWrapper .buttonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}