.BranchCardWrapper {
    width: 100%;
    height: auto;
}

.BranchCardWrapper .avatarWrapper {
    width: 30px;
    height: 30px;
   transition: .2s;
   margin-right: 15px;
}

.BranchCardWrapper img {
    width: 100px;
    height: 100%;
    object-fit: cover;
}

.BranchCardWrapper .buttonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.row-first-col {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    width: auto;
    height: 100%;

}

.BranchCardWrapper .row-second-col {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    height: 100%;
}

.BranchCardWrapper .row-second-col .avatarWrapper {
    margin-right: 0;
}
.titleAndDateWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: auto;
    height: 100%;
    padding: 5px;
}

.titleAndDateWrapper h2 {
    font-family: roboto;
    font-weight: bold;
    color: black;
    font-size: 18px;
}

.titleAndDateWrapper h3 {
    font-family: roboto;
    font-weight: 100;
    font-size: 14px;
    color: rgb(90, 90, 90)
}