:root {
    --divide-color: 0, 0, 0;
    --p-color: rgb(87, 89, 98);
}

button {
    cursor: pointer;
}

.MenuLoginWrapper {
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 100px;
}

.MenuLoginWrapper .logoAndButtonsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.MenuLoginWrapper .logoAndButtonsWrapper .brandLogo {
    max-width: 210px;
    max-height: 260px;
    width: auto;
    height: auto;
    object-fit: cover;
    margin-bottom: 25px;
}



.MenuLoginWrapper .logoAndButtonsWrapper .language {
    margin-bottom: 1.5rem;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    color: rgb(var(--primary));
    border: none;
    background: transparent;
    cursor: pointer
}

.MenuLoginWrapper .logoAndButtonsWrapper .language>svg {
    width: 20px;
    height: 20px;
}

.MenuLoginWrapper .logoAndButtonsWrapper .language>span {
    text-transform: capitalize;
    text-decoration: underline;
    font-size: 16px
}

.MenuLoginWrapper .logoAndButtonsWrapper .goMenu {
    width: 350px;
    height: 40px;
    background: rgb(var(--primary));
    border: none;
    border-radius: 0.375rem;
    color: #fff;
    font-size: 1rem;
    line-height: 1.5rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.MenuLoginWrapper .logoAndButtonsWrapper .goMenu:hover {
    background: rgba(var(--primary), 0.9);
}

.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.MenuLoginWrapper .feedBackAndInfoWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 350px;
}


.MenuLoginWrapper .feedBackAndInfoWrapper button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: none;
    border-top: 1px solid rgba(var(--divide-color), 0.1);
    padding: 20px 0;
    background: transparent;
    cursor: pointer;
}

.MenuLoginWrapper .feedBackAndInfoWrapper button:nth-child(2) {
    border-bottom: 1px solid rgba(var(--divide-color), 0.1);
}


.MenuLoginWrapper .feedBackAndInfoWrapper button span {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    color: rgb(87, 89, 98);
    font-size: 16px;
}

.MenuLoginWrapper .feedBackAndInfoWrapper button svg {
    width: 20px;
    height: 20px;
    fill: rgb(87, 89, 98);
}


.MenuLoginWrapper .buttonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    padding: 0 0 15px 0;
}


.MenuLoginWrapper .buttonsWrapper a {
    width: 40px;
    height: 40px;
    border: 1px solid rgb(var(--primary));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    cursor: pointer;
    transition: .2s;
}

.MenuLoginWrapper .buttonsWrapper a:hover {
    background: rgba(var(--primary), 0.1);
    transition: .2s;
}

.MenuLoginWrapper .buttonsWrapper a svg {
    fill: rgb(var(--primary));
}
