.countBoxsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    margin-bottom: 45px;

    @media screen and (max-width: 1000px) {
        gap: 5px;
    }
}

.countBox {
    width: 240px;
    padding: 15px;
    background: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;


}

.countBox h5 {
    font-size: 22px;
    font-weight: lighter;

    @media screen and (max-width: 1000px) {
        font-size: 12px;
    }
}

.countBox span {
    font-size: 34px;
    font-weight: bolder;

    @media screen and (max-width: 1000px) {
        font-size: 20px;
    }
}

.cardRow {
    border-radius: 8px !important;
    width: 100%;
    max-width: 900px;
}

.cardRow:hover {
    background: #f3f3fb !important;
}

.cardRow.active {
    background: #f0f0ff;
}

.tableHead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.pagination {
    margin-top: 15px;
}

.fab {
    position: fixed !important;
    bottom: 45px !important;
    right: 20px !important;
    background: #1b1d21 !important;
}

.fab * {
    fill: #fff !important;
}

.fab:hover {
    background: #fff !important;
}

.fab:hover * {
    fill: #1b1d21 !important;
}

.paginationRowWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 5px;
    }
}

.categoryAndProductsWrapper {
    background: #1b1b1b11;
    padding: 5px;
    border-radius: 8px;
}

.customAcordion {
}

.customAcordionSummary > div {
    margin: 0;
    padding: 5px;
}