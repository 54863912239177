.AdminHeaderWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    height: 64px;
    top: 55px;
    right: 35px;
    width: calc(100% - var(--leftSideWrapperWidth) - 35px - 35px);
    background: transparent;
    padding: 0;
    box-shadow: none;
    z-index: 999;

    @media screen and (max-width: 1000px) {
        width: 100%;
        right: 0;
        flex-direction: column;
        flex-direction: column-reverse;
        height: auto;
        top: 0;
        padding: 15px 5px;
        position: fixed;
        background: #fff;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }
}

.AdminHeaderWrapper>div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: inherit;
    padding: 0;
    width: auto;
}

.AdminHeaderWrapper svg {
    fill: #1b1d21
}

.AdminHeaderWrapper * {
    color: #1b1d21;
}

.AdminHeaderWrapper .searchInputWrapper {
    width: 240px;
    height: 50px;
    border-radius: 100px;
    border: 2px solid transparent;
    transition: .1s;
    position: relative;

    @media screen and (max-width: 1000px) {
        width: 100%;
    }
}

.AdminHeaderWrapper .searchInputWrapper:hover {
    border: 2px solid #1b1d21;
    transition: .1s;
}

.AdminHeaderWrapper .searchInputWrapper input {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    border: none;
    padding: 0 15px 0 45px;
    outline-style: none;
}

.AdminHeaderWrapper .searchInputWrapper svg {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
}

.headerButtonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1000px) {
        overflow-x: hidden;
    }
}

.headerButtonsWrapper>button {
    width: 48px;
    height: 48px;
}