.MenuTemplateLeftWrapper:has(.SearchPageWrapper)>.HeaderWrapper {
    display: none;
}

.MenuTemplateLeftWrapper:has(.SearchPageWrapper)>div {
    margin-top: 0;
}

.SearchPageWrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.SearchPageWrapper .goBackButton {
    border: none;
    background: transparent;
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.SearchPageWrapper .goBackButton svg {
    width: 100%;
    height: 100%;
    stroke: var(--p-color)
}

.SearchPageWrapper header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: auto;
    padding: 16px;
    gap: 1rem;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

@media screen and (max-width: 500px) {
    .SearchPageWrapper header {
        width: 100%;
    }
}


.SearchPageWrapper .searchInputWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
}

.searchInputWrapper>svg {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%)
}

.searchInputWrapper>button {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    border: none;
    background: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
}

.searchInputWrapper>button svg {
    width: 100%;
    height: 100%;
}

.SearchPageWrapper header input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    border-radius: 100px;
    border: 1px solid var(--p-color);
    outline-style: none;
    padding: 0 35px;
}

.SearchPageWrapper header input:focus {
    border: 2px solid rgb(var(--primary));
}

.SearchPageWrapper .beardAndRowWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.SearchPageWrapper .beardAndRowWrapper>p {
    color: rgba(87, 89, 98, 0.5);
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
    text-align: left;
    width: 100%;
    text-transform: capitalize;

}

.SearchPageWrapper body {
    width: 100%;
    height: calc(100vh - 72px - 80px);
    background: rgb(247, 247, 247);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 18px 16px 96px;
    gap: 30px;
    overflow-y: auto;
    margin-top: 72px;
}

.SearchPageWrapper body h1 {
    width: 100%;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: var(--p-color);
    font-weight: 700;
}

.SearchPageWrapper body .productRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: auto;
    background: #fff;
    padding: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
    transition: .2s;
    gap: 10px;
    border-radius: 5px;
    opacity: 0;
    transform: translateX(-100%);
}

@keyframes product-row-start {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }

    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

.SearchPageWrapper body .productRow h4 {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
    color: rgb(87,89,98);
}

.SearchPageWrapper body .productRow p {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: rgb(87,89,98);
}

.SearchPageWrapper body .productRow span {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
    color: rgb(216,43,43);
}

.SearchPageWrapper body .productRow:hover {
    transform: translateY(4px);
    transition: .2s;
}

.SearchPageWrapper body .productRow>div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.5rem;
}

.SearchPageWrapper .tagsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
}



.SearchPageWrapper body .productRow>div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.SearchPageWrapper body .productRow>div:last-child>img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.SearchPageWrapper footer {
    width: 100%;
    padding: 16px;
}

.SearchPageWrapper footer button {
    width: 100%;
    height: 48px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    color: #fff;
    background: rgb(var(--primary));
    border-radius: 7px;
    border: none;
}