.ProfileSettingsWrapper {
    max-width: 650px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px
}
.ProfileSettingsWrapper .drawerButton {
    width: 100px;
    border-radius: 5px;
}
.ProfileSettingsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    width: 100%;
}


.ProfileSettingsRow .inputsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    width: 100%;
}

.ProfileSettingsRow .inputsWrapper > div {
    width: 100%;
}