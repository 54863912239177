.ModalWrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgb(0, 0, 0, .4);
    pointer-events: none;
    opacity: 0;
    transition: .2s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ModalWrapper.open {
    display: flex;
    pointer-events: all;
    opacity: 1;
}
.ModalBox {
    max-height: 911px;
    min-height: 150px;
    background: #fff;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 450px;
    min-width: 350px;
}


.ModalBox .header {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 16px;
}

.ModalBox .header button:first-child {
    position: absolute;
    left: 16px;
    width: 20px;
    height: 20px;
    background: transparent;
    border: none;
    color: rgb(var(--primary));
    text-decoration: underline;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
}

.ModalBox .header button:last-child {
    position: absolute;
    right: 16px;
    width: 20px;
    height: 20px;
    background: transparent;
    border: none;
    color: var(--p-color);
}

.ModalBox .header button svg {
    width: 100%;
    height: 100%;
    stroke: var(--p-color);
}


.ModalBox .body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 16px;
}

.ModalBox .body .selectLanguageButton {
    margin-bottom: 10px;
    border: none;
    background: transparent;
    font-size: 14px;
    color: var(--p-color);
    cursor: pointer;
    
}
.ModalBox .body .FilterTagsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
}
.ModalBox .body .FilterTagsWrapper h2 {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
}

.ModalBox .body .FilterTagsWrapper > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}


.ModalBox .body .FilterTagsWrapper > div > button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    font-size: 16px;
    line-height: 1.25rem;
    font-weight: 500;
    background: transparent;
    border: none;
}


.ModalBox .body .FilterTagsWrapper .footer  {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 16px 0 0 0;
    border-top: 1px solid var(--p-color);
}


.ModalBox .body .FilterTagsWrapper .footer > button  {
    width: 100%;
    height: 48px;
    background: rgb(var(--primary));
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #fff;
}


.ModalBox .body .infoWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
}

.ModalBox .body .infoWrapper h2 {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 700;
    color: var(--p-color);
    margin-bottom: 15px;
}

.ModalBox .body .infoWrapper h3 {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
    color: var(--p-color);
}

.ModalBox .body .infoWrapper p {
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--p-color);
}

.ModalBox .body .infoWrapper a {
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--p-color);
    text-decoration: underline;
    cursor: pointer;
}

.ModalBox .body .infoWrapper .titleAndText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
}

.ModalBox .body .infoWrapper .callUsOnRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
}



@media screen and (max-width: 500px) {

    .ModalWrapper {
        width: 100%;
    }

    .ModalBox {
        width: calc(100%);
        position: absolute;
        bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        /* animation: modal-mobil-open .2s linear forwards; */
    }
}

@keyframes modal-mobil-open {
    from {
        transform: translateY(100%);
        opacity: 0;
    } to {
        transform: translateY(0%);
        opacity: 1;
    }
}


@keyframes modal-mobil-close {
    from {
        transform: translateY(0%);
        opacity: 1;
    } to {
        transform: translateY(100%);
        opacity: 0;
    }
}