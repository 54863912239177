.SideMenuWrapper {
    width: 384px;
    max-width: 30%;
    height: 100%;
    position: fixed;
    background: #fff;
    transform: translateX(-100%);
    transition: .1s;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 500px) {

    .SideMenuWrapper {
        width: 100%;
        max-width: 100%;
    }
}


.SideMenuWrapper.open {
    transform: translateX(0%);
    transition: .1s;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.SideMenuWrapper .header {
    width: 100%;
    height: 48px;
    padding: 12px 16px;
}

.SideMenuWrapper .header button {
    width: 20px;
    height: 20px;
    padding: 0;
    border: none;
    background: transparent;
}

.SideMenuWrapper .header button svg {
    width: 100%;
    height: 100%;
    fill: rgb(var(--primary));

}

.SideMenuWrapper .logoRow {
    width: 100%;
    height: 100%;
    max-height: 118px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}


.SideMenuWrapper .logoRow img {
    width: auto;
    height: auto;
    max-width: 210px;
    max-height: 118px;
    object-fit: cover;
}

.SideMenuWrapper .linksCol {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.5rem;
    padding: 0 24px;
}

.SideMenuWrapper .linksCol button,
.SideMenuWrapper .linksCol a {
    background: transparent;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 600;
    color: var(--p-color);
    border: none;
    cursor: pointer;
}

.SideMenuWrapper .footer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}

.SideMenuWrapper .footer p {
    color: var(--p-color);
    padding: 0 24px;
}



.SideMenuWrapper .footer .buttonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    padding: 0 0 15px 0;
}


.SideMenuWrapper .footer .buttonsWrapper a {
    width: 40px;
    height: 40px;
    border: 1px solid rgb(var(--primary));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    cursor: pointer;
    transition: .2s;
}

.SideMenuWrapper .seperator {
    width: calc(100% - 48px);
    height: 1px;
    margin: auto;
    margin-top: 2.5rem;
    background: rgba(0, 0, 0, 0.1);
}

.SideMenuWrapper .footer .buttonsWrapper a:hover {
    background: rgba(var(--primary), 0.1);
    transition: .2s;
}

.SideMenuWrapper .footer .buttonsWrapper a svg {
    fill: rgb(var(--primary));
}

.SideMenuWrapper .linksCol button.active {
    color: rgb(var(--primary));
}