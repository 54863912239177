.MenuTemplateWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100vh;
}

.MenuTemplateLeftWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
    min-width: 500px;
    height: 100%;
    border-right: 1px solid rgb(var(--primary));
}

@media screen and (max-width: 500px) {
    .HeaderWrapper {
        min-width: 100%;
    }
    .MenuTemplateLeftWrapper {
        min-width: 100%;
        border-right: none;
    }

}

.MenuTemplateRightWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 70%;
    width: 70%;
    height: 100%;
}

.outletWrapper {
    width: 100%;
    height: 100%;
    margin-top: 48px;
}