.MenuTemplateLeftWrapper:has(.FeedbackPageWrapper)>.HeaderWrapper {
    display: none;
}

.MenuTemplateLeftWrapper:has(.FeedbackPageWrapper)>div {
    margin-top: 0;
}

.FeedbackPageWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
}

.FeedbackPageWrapper .header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 64px;
    height: 64px;
    padding: 12px;
    position: relative;
    margin-bottom: 32px;
}

.FeedbackPageWrapper .header>a {
    position: fixed;
    top: 12px;
    left: 12px;
    transform: translateY(0%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.FeedbackPageWrapper .header>a svg {
    transform: rotateZ(180deg);
    width: 16px;
    height: 16px;
}

.FeedbackPageWrapper .header>h2 {
    box-sizing: border-box;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    color: rgb(87, 89, 98);
}


.FeedbackPageWrapper .yesnoWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
}

.FeedbackPageWrapper .yesnoWrapper>p {
    box-sizing: border-box;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: normal;
    color: rgb(87, 89, 98);
}

.FeedbackPageWrapper .yesnoWrapper>div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.FeedbackPageWrapper .yesnoWrapper>div>button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    border-radius: 100px;
    border: 2px solid rgb(var(--primary));
    background: transparent;
    color: rgb(var(--primary));
    width: 90px;
    height: auto;
    font-size: 16px;
    font-weight: 600;
}

.FeedbackPageWrapper .yesnoWrapper>div>button.active {
    color: #fff;
    background: rgb(var(--primary));
}

.FeedbackPageWrapper .giveStarWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
}

.FeedbackPageWrapper .giveStarWrapper p {
    box-sizing: border-box;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: normal;
    color: rgb(87, 89, 98);
}


.FeedbackPageWrapper .textAndInputWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 32px;
    margin-bottom: 32px;
}




.FeedbackPageWrapper .textAndInputWrapper p {
    box-sizing: border-box;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: normal;
    color: rgb(87, 89, 98);
}




.FeedbackPageWrapper .textAndInputWrapper input {
    box-sizing: border-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    border-color: rgba(87, 89, 98, 0.2);
    padding: 8px 12px;
    border-width: 1px;
    border-style: solid;
    border-radius: 100px;
    width: 100%;
}

.FeedbackPageWrapper button.submit {
    box-sizing: border-box;
    background-color: rgb(216, 43, 43);
    border: 1px solid rgb(216, 43, 43);
    width: 100%;
    min-height: 50px;
    height: 50px;
    color: rgb(255, 255, 255);
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    appearance: none;
    outline: none;
    user-select: none;
    cursor: pointer;
    padding: 9px 12px;
}

.FeedbackPageWrapper button.submit:disabled {
    background-color: rgb(216, 43, 43, .3);
    border: 1px solid rgb(216, 43, 43, .3);
    cursor: not-allowed;
}

.FeedbackPageWrapper .thanksP {
    box-sizing: border-box;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: normal;
    color: rgb(87, 89, 98);
}