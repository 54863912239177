.imagePickerWrapper {
    width: 150px;
    height: 100px;
    position: relative;
    border: 1px solid #a8a8a8;
    border-radius: 4px;
}

.imagePickerWrapper .previewImage {
    width: calc(100% - 10px);
    height: auto;
    max-height: calc(100% - 10px);
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.imagePickerWrapper .deleteButton {
    z-index: 99;
    position:relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.imagePickerWrapper input {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
}

.imagePickerWrapper svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 45px;
    height: auto;
    fill: #a8a8a8;
    transition: .3s;

}

.imagePickerWrapper.selected:hover svg {
    fill: #fff;
    background: #a8a8a8;
    border-radius: 100px;
    transition: .3s;
}



