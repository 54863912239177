.MessagesWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: calc(100vh - 64px);
    padding: 25px 0 50px 0;
}

.MessagesWrapper .messageList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    width: 100%;
    max-width: 600px;
}

.MessagesWrapper .filterButtonsWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    background: rgb(172, 172, 172);
    border-radius: 100px;
    padding: 4px;
    margin-bottom: 45px;
    
    @media screen and (max-width: 1000px) {
        gap: 5px;
            
        }
} 

.MessagesWrapper .filterButton {
    width: 50%;
    border: 2px solid #fff;
    background: #fff;
    border-radius: inherit;
    transition: .2s;
    color: #1b1b1b;
    font-size: 18px;
    padding: 10px 0;
    @media screen and (max-width: 1000px) {
    font-size: 14px;
        
    }
}
.MessagesWrapper .filterButton.active {
    border: 2px solid #1b1b1b;
    background: #1b1b1b;
    color: #fff;
}